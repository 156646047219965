var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('button',{directives:[{name:"can",rawName:"v-can",value:('teaching.add_matterevaluationbibliographicresource'),expression:"'teaching.add_matterevaluationbibliographicresource'"}],staticClass:"btn btn-secondary btn-sm",on:{"click":function($event){return _vm.$bvModal.show(`new-m_evaluation_bibliographic_resource-modal`)}}},[_vm._v(" Agregar Recurso Bibliográfico ")]):_vm._e()])]),_c('GenericBTable',{attrs:{"items":_vm.m_evaluation_bibliographic_resource,"pagination":_vm.m_evaluation_bibliographic_resource.length,"fields":_vm.MEvaluationBibliographicResourceFields,"filter":_vm.input_search,"filterCustom":_vm.filterCustom,"show_pagination":true,"search_filter":true,"columns_display":false,"allows_crud":_vm.allows_crud,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"emitChangeSearchField":_vm.changeInputSearch,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(bibliographic_resource)",fn:function(row){return [_c('span',{staticClass:"rich-text-content",domProps:{"innerHTML":_vm._s(
          _vm.getBibliographicResourceDescription(row.item.bibliographic_resource)
        )}})]}},(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
          `Editar Recurso Bibliográfico`
        ),expression:"\n          `Editar Recurso Bibliográfico`\n        ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(
            `edit-m_evaluation_bibliographic_resource-modal-${row.item.id}`
          )}}}),_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
          `Eliminar Recurso Bibliográfico`
        ),expression:"\n          `Eliminar Recurso Bibliográfico`\n        ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.askForDeleteMEvaBiblioResource(row.item.id)}}}),_c('b-modal',{attrs:{"id":`edit-m_evaluation_bibliographic_resource-modal-${row.item.id}`,"title":`Editar Recurso Bibliográfico`,"size":"md","hide-footer":""}},[_c('MEvaluationBibliographicResourceForm',{attrs:{"MatterEvaBibliResource":row.item,"m_evaluation_id":_vm.m_evaluation_id,"bibliographic_resources":_vm.bibliographic_resources,"bibliographic_resource_types":_vm.bibliographic_resource_types},on:{"updated":_vm.slotUpdatedMEvaBiblioResource}})],1)]}}:null],null,true)}),_c('b-modal',{attrs:{"id":`new-m_evaluation_bibliographic_resource-modal`,"hide-footer":true,"title":`Crear Recurso Bibliográfico`,"size":"lg"}},[_c('MEvaluationBibliographicResourceForm',{attrs:{"m_evaluation_id":_vm.m_evaluation_id,"bibliographic_resources":_vm.bibliographic_resources,"bibliographic_resource_types":_vm.bibliographic_resource_types},on:{"created":_vm.slotCreatedMEvaBiblioResource}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }